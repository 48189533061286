import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";
import memoize from "memoize-one";

import VideoCard from "./VideoCard.js"

const CARD_SIZE = 290;

const styles = theme => ({
  root: {
    padding: "10px 15px",
    marginTop: 5,
    justifyContent: "center",

  },
  Row: {
    display: "flex",
    justifyContent: "space-evenly",
    backgroundColor:"#FAFAFA",
  


  },
  RowParent:{

  },
  AutoSizerList:{
    backgroundColor:"#FAFAFA",
    overflowY: "hidden",
    "&:hover": {
      overflowY:"overlay"
    },
    [theme.breakpoints.down('sm')]: {
      overflowY:"scroll" 
    },


  },
  AutoSizerParent:{
    height: "93vh",
    overflowY:"hidden",
    [theme.breakpoints.down('sm')]: {
      overflowY:"scroll" 
    },

    

  }
});

class Row extends PureComponent {


  render() {
    const { data, index, style } = this.props;

  //  console.log(data)


    const { classes, itemsPerRow, locations } = data;
 
    const items = [];
    const fromIndex = index * itemsPerRow;
    const toIndex = Math.min(fromIndex + itemsPerRow, locations.length);

 

    for (let i = fromIndex; i < toIndex; i++) {
      items.push(
        <VideoCard key={i} item={locations[i]} />
      );
    }

  //  console.log(items)

    return (
      <div className={classes.Row} style={style}>
        {items}
      </div>
    );
  }
}

class VideosGrid extends PureComponent {
  constructor(props) {
    super(props)

    // Create the ref

    this.listRef = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
 
    if(nextProps.locations.length === 0 || this.props.locations.length === 0) {
      return;
    }
    else if (typeof(nextProps.locations) !== "undefined") {
      let id = nextProps.locations[0].node.id
      if (id !== this.props.locations[0].node.id) {
        this.listRef.current.scrollToItem(0, "center");
      }
    }
    else {
      return;
    }
  }

  getItemData = memoize((classes, itemsPerRow, locations) => ({
    classes,
    itemsPerRow,
    locations
  }))

  render() {

  const { locations, classes } = this.props;



    return (
      <div className={classes.AutoSizerParent}>
        <AutoSizer>
          {({ height, width }) => {
            const itemsPerRow = Math.floor(width / CARD_SIZE) || 1;
      
            const rowCount = Math.ceil(locations.length / itemsPerRow);
            const itemData = this.getItemData(classes, itemsPerRow, locations);
      
            return (  
              <>
               
                <List
                  height={height}
                  itemCount={rowCount}
                  itemData={itemData}
                  itemSize={CARD_SIZE}
                  width={width}
                  ref={this.listRef}
                  
                  className={classes.AutoSizerList}
              
                >
                  {Row}
                </List>
              </>
            );
          }}
        </AutoSizer>
      </div>
    );
  }
}

VideosGrid.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(VideosGrid);