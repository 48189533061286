import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"

import Img from "gatsby-image"
import Link from "gatsby-link"
import { kebabCase } from "lodash"

const useStyles = makeStyles({
  root: {
    maxWidth: 280,
    margin: "5px 0px",
    padding:8
  },
  media: {
    height: 130,
  },
  cardTitle: {
    fontSize: ".85rem",
  },
  cardDescription: {
    fontSize: ".8rem"

  },
  cardContent:{
    padding:10
  }
})

// {item.node.optimized_thumbnail_link ? (
//   <Img
//     className={classes.media}
//     fluid={
//       item.node.optimized_thumbnail_link.childImageSharp.fluid !==
//       null
//         ? item.node.optimized_thumbnail_link.childImageSharp.fluid
//         : item.node.thumbnail_link
//     }
//     alt={"image of" + item.node.instructor}
//   />
// ) : (
//   <CardMedia
//     className={classes.media}
//     image={item.thumbnail_link}
//     alt={"image of" + item.node.title}
//     title=""
//   />
// )}

export default function VideoCard(props) {
  const classes = useStyles()

  const { item } = props

  //console.log(item.node)

  return (
    <Card className={classes.root}>
      <Link
        style={{ textDecoration: "none" }}
        state={{ modal: true}}
        to={`/video/${kebabCase(item.node.title)}/`}
      >
        <CardActionArea>
     
            <CardMedia
              className={classes.media}
              image={item.node.thumbnail_link}
              alt={"image of" + item.node.title}
              title=""
            />
 

          <CardContent  className={classes.cardContent}>
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              className={classes.cardTitle}
            >
              {item.node.title}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              className={classes.cardDescription}
            >
              {item.node.description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Link>
    </Card>
  )
}
